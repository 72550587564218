
body {
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
    transition: transform 0.2s ease-in-out; /* Add a smooth transition for zooming */
    transform-origin: top left; /* Zoom from the top-left corner */
    /*transform: translate3d(0, 0, 0) scale(${zoomLevel});*/
    /*transition: transform 0.2s ease-in-out;*/
}

::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: white;
}

/*.main-loading div {*/
/*    width: max-content;*/
/*    width: -moz-max-content;*/
/*    height: max-content;*/
/*}*/

.main-content {
    position: relative;
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
    /*background-size: 140%;*/
    /*background-position: top left !important; !* Corrected background-position *!*/
    /*background-repeat: no-repeat;*/
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../public/bg/main.webp);
    transition: transform 0.2s ease-in-out; /* Add a smooth transition for zooming */
    transform-origin: top left;
}

.main-content .background-images {
    position: absolute;
    display: none;
    /*display: -webkit-flex;*/
    justify-content: center;
    -webkit-justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.main-content .background-images .bg-1,
.main-content .background-images .bg-2 {
    width: 50%;
    height: 100%;
}

/*.main-content .background-images .bg-1 {*/
/*    position: relative;*/
/*    background-size: 190%;*/
/*    background-position: 74% 40% !important;*/
/*    background-repeat: no-repeat;*/
/*    background-image: url(../public/bg/new-bg-2.webp);*/
/*    transform: scaleX(-1);*/
/*}*/

/*.main-content .background-images .bg-2 {*/
/*    background-size: 170%;*/
/*    background-position: 70% 40% !important;*/
/*    background-repeat: no-repeat;*/
/*    !*background-size: cover;*!*/
/*    !*background-position: center center;*!*/
/*    !*background-repeat: no-repeat;*!*/
/*    background-image: url(../public/bg/bg-3.webp);*/
/*    transform: scaleX(-1);*/
/*}*/

.main-content:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
}

.main-content .main-content-inner {
    position: relative;
    z-index: 3;
}

.book-content {
    transition: transform 0.2s ease-in-out; /* Add a smooth transition for zooming */
}

.react-pdf__Document {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
}

.stf__parent {
    /*width: 394px !important;*/
    /*margin: 0 auto;*/
    /*min-width: 100% !important;*/
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../public/bg/wood-background.webp);
}

.stf__wrapper {
    /*margin: 0 auto !important;*/
}

.stf__parent .stf__block {
    /*width: 394px !important;*/
    display: flex;
    justify-content: center;
    transform: scale(0.95);
    /*width: max-content !important;*/
}

.stf__parent .stf__block .stf__item:first-child {
    /*left: 0 !important;*/
    /*left: 50% !important;*/
    /*transform: translateX(-50%) !important;*/
    /*transition: 0.5s all;*/
}

.slide-btn.slide-btn-left {
    left: 50px;
    z-index: 4;
}

.slide-btn.slide-btn-right {
    right: 50px;
    z-index: 4;
}

/*Video Modal*/
.ReactModal__Overlay {
    display: flex;
    display: -o-flex;
    display: -ms-flex;
    display: -moz-flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6) !important;
}

.video-modal {
    width: 60%;
    max-width: 1000px;
    margin: 0 auto;
}

.video-modal .modal-content {
    padding: 6px 15px 15px;
    border-radius: 6px;
    background-color: white;
}

.video-modal .modal-body {
    position: relative;
    padding-top: 56.25%;
}

.video-modal .modal-body iframe {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 6px;
}
/*Video Modal*/

/*Full View Toggle*/
.full-view-toggle {
    position: absolute;
    display: flex;
    display: -o-flex;
    display: -ms-flex;
    display: -moz-flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    z-index: 4;
    border-radius: 50%;
    background-color: white;
}
/*Full View Toggle*/

/* Apply 100px width to .stf__block with exactly 1 .stf__item */
/*.stf__block .stf__item:first-child {*/
/*    left: 50% !important;*/
/*    transform: translateX(-50%) !important;*/
/*}*/

/*Top Buttons*/
/*.top-btns {*/
/*    position: absolute;*/
/*    display: flex;*/
/*    display: -o-flex;*/
/*    display: -ms-flex;*/
/*    display: -moz-flex;*/
/*    display: -webkit-flex;*/
/*    justify-content: center;*/
/*    -webkit-justify-content: center;*/
/*    align-items: center;*/
/*    -webkit-align-items: center;*/
/*    top: 5px;*/
/*    left: 50%;*/
/*    padding: 6px 12px;*/
/*    z-index: 4;*/
/*    gap: 10px;*/
/*    border-radius: 4px;*/
/*    background-color: #FFFFFF;*/
/*    transform: translateX(-50%);*/
/*}*/

/*.top-btns .zoom-icon {*/
/*    color: #1e293b;*/
/*    width: 16px;*/
/*    height: 16px;*/
/*}*/
/*Top Buttons*/

.zoom-slider {
    position: absolute;
    /*display: none;*/
    /* width: 100%; */
    z-index: 5;
    bottom: 16%;
    left: 50%;
    transform: translateX(-50%);
}

.zoom-slider .zoom-text {
    color: white;
    font-size: 14px;
    line-height: 1;
}

.zoom-btn {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
    padding: 10px;
    border-radius: 50%;
    background-color: #ffffff;
}

.zoom-btn .zoom-icon {
    color: #1e293b;
    width: 16px;
    height: 16px;
}

/*Zoom Modal*/
.zoom-modal {
    cursor: zoom-modal;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    z-index: 3;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.zoom-modal .zoom-modal-container {
    transform-origin: 5px 20px;
}

.zoom-modal .react-pdf__Page {
    display: flex;
    display: -o-flex;
    display: -ms-flex;
    display: -moz-flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
}

.zoom-modal .react-pdf__Page .react-pdf__Page__annotations {
    display: none;
}
/*Zoom Modal*/

/*Sidebar*/
.sidebar, .pagebar {
    position: absolute;
    top: 46px;
    z-index: 10;
    background-color: black;
    min-width: 250px;
    height: 100%;
    display: flex;
    display: -o-flex;
    display: -ms-flex;
    display: -moz-flex;
    display: -webkit-flex;
    flex-direction: column;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    transition: transform 0.3s ease;
}

.pagebar {
    max-width: 290px;
}

.sidebar .sidebar-menu, .pagebar .pagebar-menu {
    max-height: 100%;
    overflow-x: hidden;
    overflow-x: -moz-hidden-unscrollable;
    overflow-y: auto;
    -ms-overflow-y: auto;
}
/*Sidebar*/

/*Share Modal*/
.share-modal-content {
    top: 50%;
    left: 50%;
    width: 500px;
    max-width: calc(100% - 30px);
    transform: translate(-50%, -50%);
}

.share-link-input {
    width: calc(100% - 86px);
}

.qr-img {
    max-width: 120px;
}
/*Share Modal*/

/*Page Bar*/
.pagebar-content {
    max-width: 100px;
}

.pagebar-content:first-child {
    justify-content: center;
    -webkit-justify-content: center;
    min-width: 100%;
}

.pagebar-content img {
    max-width: 100px;
}
/*Page Bar*/

@media (max-width: 319px) {
    * {
        display: none !important;
    }
}

/*Landscape*/
/* Mobile Devices */
@media (max-height: 575px) and (max-width: 1200px) and (orientation: landscape) {

    /*.main-content {*/
    /*    overflow: auto !important;*/
    /*}*/

    .zoom-slider {
        display: block !important;
    }

    .main-content .background-images .bg-1,
    .main-content .background-images .bg-2 {
        width: 50% !important;
        height: 100% !important;
    }

    .main-content .background-images .bg-1 {
        position: relative;
        background-size: 190% !important;
        background-position: 74% 40% !important;
        background-repeat: no-repeat;
        background-image: url(../public/bg/new-bg-2.webp);
        transform: scaleX(-1);
    }

    .main-content .background-images .bg-2 {
        background-size: 170% !important;
        background-position: 100% 9% !important;
        background-repeat: no-repeat;
        /*background-size: cover;*/
        /*background-position: center center;*/
        /*background-repeat: no-repeat;*/
        background-image: url(../public/bg/bg-3.webp);
        transform: scaleX(-1) !important;
    }
}

@media (min-width: 320px) and (max-width: 480px) {

    .book-content {
        width: calc(100% - 40px);
    }

    .pagebar {
        max-width: 320px;
    }

    .pagebar-main-content {
        gap: 10px;
    }

    .pagebar-content, .pagebar-content img {
        max-width: 86px;
    }

    .pagebar-content:first-child {
        justify-content: center;
        -webkit-justify-content: center;
        min-width: 86px;
    }

    /*.stf__parent, .stf__item, .react-pdf__Page, .stf__item canvas, .react-pdf__Page__annotations.annotationLayer {*/
    /*    width: 100% !important;*/
    /*    min-width: 100% !important;*/
    /*    max-width: 100% !important;*/
    /*}*/

    /*.stf__item {*/
    /*    left: 0 !important;*/
    /*}*/

    /*.main-content {*/
    /*    overflow: auto !important;*/
    /*}*/

    .zoom-btn {
        display: block !important;
    }

    .stf__item:first-child {
        /*position: relative !important;*/
        /*top: unset !important;*/
        /*top: 50% !important;*/
        /*transform: translateY(-50%) !important;*/
    }

    .slide-btn.slide-btn-left {
        left: 0;
    }

    .slide-btn.slide-btn-right {
        right: 0;
    }

    /*Video Modal*/
    .video-modal {
        width: calc(100% - 30px);
    }

    .video-modal .modal-content {
        padding: 6px 10px 10px;
    }
    /*Video Modal*/

    .main-content .background-images .bg-1 {
        width: 100%;
        background-size: cover;
    }

    .main-content .background-images .bg-2 {
        display: none;
    }
}

@media (min-width: 481px) and (max-width: 576px) {

    /*.main-content {*/
    /*    overflow: auto !important;*/
    /*}*/

    .book-content {
        width: calc(100% - 40px);
    }

    .zoom-btn {
        display: block !important;
    }

    .slide-btn.slide-btn-left {
        left: 0;
    }

    .slide-btn.slide-btn-right {
        right: 0;
    }

    /*Video Modal*/
    .video-modal {
        width: calc(100% - 30px);
    }

    .video-modal .modal-content {
        padding: 6px 10px 10px;
    }
    /*Video Modal*/

    .main-content .background-images {
        flex-wrap: wrap;
    }

    .main-content .background-images .bg-1,
    .main-content .background-images .bg-2 {
        width: 100%;
        height: 50%;
    }

    .main-content .background-images .bg-1 {
        background-size: 140%;
        background-position: 50% 80% !important;
    }

    .main-content .background-images .bg-2 {
        background-size: 140%;
        background-position: 0 50% !important;
    }

    .pagebar {
        max-width: 320px;
    }

    .pagebar-main-content {
        gap: 10px;
    }

    .pagebar-content, .pagebar-content img {
        max-width: 86px;
    }

    .pagebar-content:first-child {
        justify-content: center;
        -webkit-justify-content: center;
        min-width: 86px;
    }
}

@media (min-width: 577px) and (max-width: 768px) {

    /*.main-content {*/
    /*    overflow: auto !important;*/
    /*}*/

    .book-content {
        width: calc(100% - 40px);
    }

    .zoom-btn {
        display: block !important;
    }

    .slide-btn.slide-btn-left {
        left: 5px;
    }

    .slide-btn.slide-btn-right {
        right: 5px;
    }

    /*Video Modal*/
    .video-modal {
        width: calc(100% - 30px);
    }

    .video-modal .modal-content {
        padding: 6px 10px 10px;
    }
    /*Video Modal*/

    .main-content .background-images {
        flex-wrap: wrap;
    }

    .main-content .background-images .bg-1,
    .main-content .background-images .bg-2 {
        width: 100%;
        height: 50%;
    }

    .main-content .background-images .bg-1 {
        background-size: 140%;
        background-position: 50% 80% !important;
    }

    .main-content .background-images .bg-2 {
        background-size: 140%;
        background-position: 0 50% !important;
    }

    .pagebar {
        max-width: 320px;
    }

    .pagebar-main-content {
        gap: 10px;
    }

    .pagebar-content, .pagebar-content img {
        max-width: 86px;
    }

    .pagebar-content:first-child {
        justify-content: center;
        -webkit-justify-content: center;
        min-width: 86px;
    }
}

@media only screen and (min-width: 720px) and (max-height: 540px) and (orientation: landscape) {

    /*.main-content {*/
    /*    overflow: auto !important;*/
    /*}*/

    .book-content {
        width: calc(100% - 40px);
    }

    .zoom-btn {
        display: block !important;
    }

    .main-content .background-images .bg-1,
    .main-content .background-images .bg-2 {
        width: 50% !important;
        height: 100% !important;
    }

    .main-content .background-images .bg-1 {
        background-size: 226% !important;
        background-position: 72% 44% !important;
    }

    .main-content .background-images .bg-2 {
        background-size: 226% !important;
        background-position: 65% 10% !important;
    }
}

@media (min-width: 769px) and (max-width: 991px) {

    /*.main-content {*/
    /*    overflow: auto !important;*/
    /*}*/

    .book-content {
        width: calc(100% - 40px);
    }

    .zoom-btn {
        display: block !important;
    }

    /*Video Modal*/
    .video-modal {
        width: 80%;
    }

    .video-modal .modal-content {
        padding: 6px 10px 10px;
    }
    /*Video Modal*/

    .main-content .background-images {
        flex-wrap: wrap;
    }

    .main-content .background-images .bg-1,
    .main-content .background-images .bg-2 {
        width: 100%;
        height: 50%;
    }

    .main-content .background-images .bg-1 {
        background-size: 140%;
        background-position: 50% 80% !important;
    }

    .main-content .background-images .bg-2 {
        background-size: 140%;
        background-position: 0 50% !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    /*.main-content {*/
    /*    overflow: auto !important;*/
    /*}*/

    /*Video Modal*/
    .video-modal {
        width: 80%;
    }

    .video-modal .modal-content {
        padding: 6px 10px 10px;
    }
    /*Video Modal*/

    .main-content .background-images .bg-1,
    .main-content .background-images .bg-2 {
        width: 50%;
        height: 100%;
    }

    .main-content .background-images .bg-1 {
        background-size: 226% !important;
        background-position: 72% 44% !important;
    }

    .main-content .background-images .bg-2 {
        background-size: 226% !important;
        background-position: 65% 10% !important;
    }
}

/*Ipad Portrait*/
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .main-content .background-images {
        flex-wrap: wrap;
    }

    .main-content .background-images .bg-1,
    .main-content .background-images .bg-2 {
        width: 100%;
        height: 50%;
    }

    .main-content .background-images .bg-1 {
        background-size: 140%;
        background-position: 50% 80% !important;
    }

    .main-content .background-images .bg-2 {
        background-size: 140%;
        background-position: 0 50% !important;
    }
}

/*Ipad Landscape*/
@media only screen and (min-width: 1366px) and (max-height: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    .main-content .background-images .bg-1,
    .main-content .background-images .bg-2 {
        width: 50%;
        height: 100%;
    }

    .main-content .background-images .bg-1 {
        background-size: 226% !important;
        background-position: 72% 44% !important;
    }

    .main-content .background-images .bg-2 {
        background-size: 226% !important;
        background-position: 65% 10% !important;
    }
}

@media (min-width: 1200px) and (max-width: 1365px){
    /*.book-content {*/
    /*    max-width: 1000px;*/
    /*}*/

    .book-content {
        width: calc(100% - 40px);
    }

    .slide-btn.slide-btn-left {
        left: 0;
    }

    .slide-btn.slide-btn-right {
        right: 0;
    }

    .main-content .background-images .bg-2 {
        background-size: 170%;
    }
}

@media (min-width: 1366px) and (max-width: 1399px){
    .book-content {
        max-width: 60%;
    }

    .slide-btn.slide-btn-left {
        left: 0;
    }

    .slide-btn.slide-btn-right {
        right: 0;
    }
}

@media (min-width: 1400px) and (max-width: 1919px){
    .book-content {
        max-width: 75%;
    }

    .slide-btn.slide-btn-left {
        left: 0;
    }

    .slide-btn.slide-btn-right {
        right: 0;
    }
}

@media (min-width: 1920px) {
    .book-content {
        max-width: 65%;
    }

    .slide-btn.slide-btn-left {
        left: 0;
    }

    .slide-btn.slide-btn-right {
        right: 0;
    }

    .main-content .background-images .bg-2 {
        background-size: 170%;
    }
}